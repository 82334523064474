import React from "react";
import { Tab, Tabs, Box } from "@mui/material";

export default function Products() {
  const [value, setValue] = React.useState("web");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  return (
    <>
      <div className="product-top-view"></div>
      <Box display="flex" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="web" label="Website" />
          <Tab value="android" label="Android App" />
          <Tab value="ios" label="IOS App" />
          <Tab value="api" label="API" />
        </Tabs>
        
      </Box>
    </>
  );
}
