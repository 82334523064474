import React from 'react'
import "../utils/style.css";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export default function ProfileCard({data}) {
    const style = {
        paper: { maxWidth: "300px", padding: "20px" },
        center: { display: "flex", justifyContent: "center" },
        nameText: {
          display: "flex",
          justifyContent: "center",
          fontWeight: "bolder",
          marginTop: "20px",
        },
        bioText: {
          display: "flex",
          justifyContent: "center",
          fontSize: "12px",
          fontWeight: "bold",
          color: "gray",
          marginBottom: "20px",
        },
        btnContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        },
        container: {  display:'flex', justifyContent:'center'},
      };
  return (
    data && (
        <div className="container" style={style.container}>
        <Paper elevation={5} style={style.paper}>
          <div className="row" style={style.center}>
            <div className="col-12" style={style.center}>
              <div className="outer-profile-circle">
                <Avatar
                  src={data.image}
                  alt="profile image"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
            <div className="col-12">
              <Typography variant="h5" style={style.nameText}>
                {data.name}
              </Typography>
            </div>
            <div className="col-12">
              <Typography style={style.bioText}>{data.bio}</Typography>
            </div>
  
            <div className="col-12">
              <Typography style={style.bioText}>{data.qoutes}</Typography>
            </div>
  
            <div className="col-12" style={style.btnContainer}>
              <Button variant="contained" size="small">
                Profile
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{ marginLeft: "25px" }}
                endIcon={<SendIcon />}
              >
                Message
              </Button>
            </div>
          </div>
        </Paper>
      </div>
      )
  )
}
