import React from "react";
import { Grid, Link } from "@mui/material";
import { Facebook, Instagram, WhatsApp, Email } from "@mui/icons-material";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "rgb(19, 26, 34)",
        color: "#fff",
        padding: "20px",
      }}
    >
      <div style={{ maxWidth: "960px", margin: "0 auto" }}>
        <Grid container justifyContent="center" spacing={2} alignItems="center">
          <Grid
            container
            justifyContent="center"
            spacing={2}
            alignItems="center"
            marginTop={5}
          >
            <Grid item>
              <Link
                href="https://www.facebook.com/shivrajit.mourya.1"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <Facebook />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://www.instagram.com/shivmourya20/"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <Instagram />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://wa.me/9608413215"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <WhatsApp />
              </Link>
            </Grid>

            <Grid item>
              <Link
                href="mailto:shivmouryacse@gmail.com"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <Email />
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <Link
              href="/privacy-policy"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="/terms-of-service"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Terms of Service
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="/refund-policy"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Refund & Return Policy
            </Link>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};

export default Footer;
