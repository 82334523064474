import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Dashboard from "./components/Dashboard";
import DeveloperPage from "./components/DeveloperPage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import JoinUs from "./components/JoinUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Products from "./components/Products";
import Services from "./components/Services";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsOfService from "./components/TermsOfService";
import RefundReturnPolicyPage from "./components/RefundReturnPolicyPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service" element={<Services />} />
          <Route path="/product" element={<Products />} />
          <Route path="/join" element={<JoinUs />} />
          <Route path="/developer-profile" element={<DeveloperPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService/>} />
          <Route path="/refund-policy" element={<RefundReturnPolicyPage/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
