import * as React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Container,
  Box,
  Tooltip,
  Dialog,
  Snackbar,
  Alert,
} from "@mui/material";
import MdPhone from "@mui/icons-material/Phone";
import MenuIcon from "@mui/icons-material/Menu";
import LoginForm from "../forms/LoginForm";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ApiHandler from "../backend/ApiHandler";
import { useNavigate } from "react-router-dom";
import "../utils/style.css";
import Divider from '@mui/material/Divider';


const settings = ["Profile", "Account", "Dashboard", "Logout"];
const loginOptions = ["Developer Login", "Client Login", "Register"];

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElUserService, setAnchorElUserService] = React.useState(null);
  const [anchorElSupport, setAnchorElSupport] = React.useState(null);
  const [loginStatus, setLoginStatus] = React.useState(false);
  const [openLoginForm, setOpenLoginForm] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const navigation = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigation = (path) => {
    navigation(`${path}`);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (option) => {
    setAnchorElUser(null);
    if (option === "Developer Login") {
      setOpenLoginForm(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = async (data) => {
    setLoading(true);
    try {
      const handler = new ApiHandler();
      const response = await handler.developerLogin(data.Id, data.Password);
      if (response.status === "success") {
        setLoginStatus(true);
        setUserData(response.data);
        setLoading(false);
      } else {
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "rgb(19, 26, 34)" }}>
      <Container maxWidth="xl" style={{}}>
        <Toolbar
          disableGutters
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: "center",
            }}
          >
            <img
              src="/logo192.png"
              alt="Tekshu Innovations Logo"
              style={{
                width: "40px",
                height: "40px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
              sx={{
                width: { xs: "10px", md: "40px" }, // smaller size for small screens
                height: { xs: "10px", md: "40px" }, // smaller size for small screens
                marginBottom: { xs: "0", md: "10px" },
                marginTop: { xs: "0", md: "10px" },
              }}
            />

            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" }, // smaller size for small screens
                marginLeft: { xs: "10px", md: "0" }, // add margin for small screens to separate image and text
              }}
            >
              Tekshu Innovations
            </Typography>
          </Box>

          <Box
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: { md: "flex", xs: "none" },
                justifyContent: "flex-end",
              }}
            >
              <p className="nav-link">
                <MdPhone />
                +919608413215
              </p>
              <p
                className="nav-link"
                onClick={(event) => {
                  setAnchorElSupport(event.currentTarget)
                }}
              >
                Support
                <KeyboardArrowDownIcon />
              </p>
              <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorElSupport}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElSupport)}
                onClose={() => {
                  setAnchorElSupport(null)
                }}
              >
                <MenuItem>Help Center</MenuItem>
                <Divider component="li" />
                <MenuItem>Contact Tekshu Innovation</MenuItem>
              </Menu>
              <Tooltip
                title={loginStatus ? "" : "Login"}
                style={{ marginLeft: "50px" }}
              >
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="Profile img"
                    src={
                      userData != null
                        ? userData.img
                        : "https://www.flaticon.com/free-icons/user"
                    }
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {loginStatus
                  ? settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        onClick={() => handleCloseUserMenu(setting)}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))
                  : loginOptions.map((option) => (
                      <MenuItem
                        key={option}
                        onClick={() => handleCloseUserMenu(option)}
                      >
                        <Typography textAlign="center">{option}</Typography>
                      </MenuItem>
                    ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { md: "flex", xs: "none" },
              }}
            >
              <li
                className="nav-link"
                onClick={() => {
                  handleNavigation("/");
                }}
              >
                Home
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  handleNavigation("/product");
                }}
              >
                Product
              </li>
              <p
                className="nav-link-dropdown"
                onClick={(event) => {
                  setAnchorElUserService(event.currentTarget)
                }}
              >
                Service
                <KeyboardArrowDownIcon />
              </p>
              <li
                className="nav-link"
                onClick={() => {
                  handleNavigation("/about");
                }}
              >
                About
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  handleNavigation("/contact");
                }}
              >
                Contact
              </li>
              <li
                className="nav-link"
                onClick={() => {
                  handleNavigation("/join");
                }}
              >
                Join Us
              </li>
            </Box>

            <Menu
              anchorEl={anchorElUserService}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorElUserService)}
              onClose={() => {
                setAnchorElUserService(null);
              }}
            >
              <MenuItem onClick={handleClose}>Android Apps</MenuItem>
              <Divider component="li" />
              <MenuItem onClick={handleClose}>Responsive Websites</MenuItem>
              <Divider component="li" />
              <MenuItem onClick={handleClose}>APIs</MenuItem>
            </Menu>

            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{ display: { md: "none", sm: "flex" } }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                "& .MuiPaper-root": {
                  width: "100vw", // Use viewport width to ensure it spans the full width
                  left: 0, // Align to the left
                  right: 0, // Align to the right
                  display: "flex", // Use flexbox layout
                  justifyContent: "center", // Center the items horizontally
                },
              }}
            >
              <MenuItem onClick={handleClose}>Home</MenuItem>
              <MenuItem onClick={handleClose}>Product</MenuItem>
              <MenuItem onClick={handleClose}>Service</MenuItem>
              <MenuItem onClick={handleClose}>About</MenuItem>
              <MenuItem onClick={handleClose}>Contact</MenuItem>
              <MenuItem onClick={handleClose}>Join Us</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Dialog open={openLoginForm}>
        <LoginForm clickLogin={handleLogin} loading={loading} />
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Login Failed
        </Alert>
      </Snackbar>
    </AppBar>
  );
}

export default Header;
