import React from "react";
import "./style.css";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Rating,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProductCarousel = ({ products, productBuy, open }) => {
  const navigate = useNavigate();
  const handleKnowMore = () => {
    navigate("/product");
  };

  const handleProductBuy = (index) => {
    open(true);
    productBuy(index);
  };


  return (
    <div className="container" style={{width:'345px'}}>
      <div id="carouselExampleRide" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {products.map((product, index) => (
            <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
              <ProductCard product={product} index={index} onClick={handleKnowMore} buy={handleProductBuy}/>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleRide"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleRide"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

const ProductCard = ({ product, index, onClick, buy }) => (
  <Card
    sx={{ maxWidth: 345, margin: "0 0" }}
    elevation={1}
    style={{ backgroundColor: "rgb(51, 72, 94)" }}
  >
    <CardMedia
      component="img"
      height="140"
      image={product.image}
      alt={product.name}
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div" color="white">
        {product.name}
      </Typography>
      <Typography variant="body2" color="white">
        {product.description}
      </Typography>
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" component="div" color="white">
          ₹{product.price}
        </Typography>
        <Rating
          name="read-only"
          value={product.rating}
          readOnly
          sx={{
            "& .MuiRating-iconEmpty": {
              color: "white", // Customize the color here
            },
          }}
        />
      </Box>
      <Box display="flex" justifyContent="center" marginTop={3}>
        <Button variant="contained" onClick={() => buy(index)}>
          Buy
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px" }}
          onClick={onClick}
        >
          Know More
        </Button>
      </Box>
    </CardContent>
  </Card>
);

export default ProductCarousel;
