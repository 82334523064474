import React, { useState } from 'react';
import { Avatar, Grid, Paper, Box, Typography } from "@mui/material";

export default function PaperModel(props) {
    const [hovered, setHovered] = useState(null);
    const { data } = props; // Destructure 'data' from props

    // Find the maximum height among descriptions
    const maxHeight = Math.max(...data.map(item => item.description.split(' ').length));

    return (
        <Grid container spacing={3} justifyContent="center" marginTop={2}>
            {data.map((item, index) => (
                <Grid item xs={9} md={3} sm={6} key={index}>
                    <Paper
                        elevation={hovered === index ? 12 : 4}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        sx={{
                            height: "100%", // Set height to 100% to match the tallest paper
                            transition: "all 0.3s ease-in-out", // Increase transition time for smoother effect
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignItems="center"
                            height="100%"
                            padding={2}
                            textAlign="center"
                        >
                            <Avatar
                                src={item.image}
                                sx={{
                                    width: { xs: 50, md: 100 },
                                    height: { xs: 50, md: 100 },
                                }}
                            />
                            <div style={{ flex: 1 }}>
                                <Typography
                                    sx={{ fontWeight: "bolder", marginTop: 2 }}
                                    variant="h6"
                                >
                                    {item.title}
                                </Typography>
                                <Typography
                                    sx={{ marginTop: 2 }}
                                    style={{ overflow: "hidden", maxHeight: `${maxHeight * 20}px`, textOverflow: "ellipsis" }}
                                >
                                    {item.description}
                                </Typography>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
}
