import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const PaymentDialog = ({ open, handleClose, product }) => {
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isCouponValid, setIsCouponValid] = useState(true);
  const theme = useTheme();

  const handleCouponChange = (e) => {
    const enteredCoupon = e.target.value.trim();
    setCoupon(enteredCoupon);

    if (enteredCoupon.length >= 9) {
      // Ensure the length is sufficient
      const couponType = enteredCoupon.slice(0, 8);
      const lastDigit = parseInt(enteredCoupon.slice(-2), 10);
      if (
        couponType === "DISCOUNT" &&
        !isNaN(lastDigit) &&
        lastDigit >= 0 &&
        lastDigit <= 99
      ) {
        setDiscount(product.price * (lastDigit / 100));
        setIsCouponValid(true);
      } else {
        setDiscount(0);
        setIsCouponValid(false);
      }
    } else if (enteredCoupon === "") {
      setDiscount(0);
      setIsCouponValid(true);
    } else {
      setDiscount(0);
      setIsCouponValid(false);
    }
  };

  const gst = product.price * 0.18;
  const finalPrice = product.price - discount + gst;

  const handlePayment = async () => {
    
    const data = {
      name: "Shivrajit Kushwaha",
      amount: finalPrice,
      contact: "9608413215"
  };

    fetch("https://tekshuinnovations.in/api/payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data.success) {
          handleClose()
          alert("Payment Success");
        }
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md" // You can set 'sm', 'md', 'lg', 'xl' based on your needs
      sx={{ "& .MuiDialog-paper": { maxWidth: "800px" } }} // Custom max width
    >
      <DialogTitle>
        Payment Information
        <Button
          onClick={handleClose}
          style={{ float: "right", color: theme.palette.grey[500] }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">{product.name}</Typography>
        <Typography variant="body1">Price: ₹{product.price}</Typography>
        <TextField
          label="Coupon Code"
          value={coupon}
          onChange={handleCouponChange}
          fullWidth
          margin="normal"
          error={!isCouponValid}
          helperText={!isCouponValid && "Invalid Coupon Code"}
        />
        {discount > 0 && (
          <Typography variant="body2" color="green">
            Discount Applied: -₹{discount.toFixed(2)}
          </Typography>
        )}
        <Typography variant="body1">GST (18%): ₹{gst.toFixed(2)}</Typography>
        <Typography variant="h6">Total: ₹{finalPrice.toFixed(2)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handlePayment} color="primary" variant="contained">
          Pay Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
