class ApiHandler {
    async developerLogin(email, password) {
        try {
          const data = new FormData()

          data.append("email",email)
          data.append("password", password)

          const response = await fetch("https://tekshuinnovations/api/login", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const result = await response.json();
          return result;
        } catch (error) {
          console.error('There was an error with the login request:', error);
          throw error;
        }
      }      
  }
  
  export default ApiHandler;
  