import React from 'react';
import { Container, Typography, Paper, Divider } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '3em' }}>
      <Paper elevation={3} style={{ padding: '2em' }}>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated: 6 June 2024
        </Typography>

        <Divider style={{ margin: '1em 0' }} />

        <Typography variant="body1" paragraph>
          Welcome to Tekshu Innovations. These Terms of Service ("Terms") govern your use of our website, services, and products. By accessing or using our services, you agree to comply with and be bound by these Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By using our website and services, you agree to these Terms, our Privacy Policy, and any additional terms applicable to specific services you use. If you do not agree, please do not use our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Services Provided
        </Typography>
        <Typography variant="body1" paragraph>
          Tekshu Innovations offers a range of technical solutions, including but not limited to:
          <ul>
            <li>App development</li>
            <li>Website development</li>
            <li>API development</li>
            <li>Technical consulting</li>
            <li>Sale of template-based app and website projects</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to use our services only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Payment Terms
        </Typography>
        <Typography variant="body1" paragraph>
          All payments for services and products must be made in accordance with the payment terms specified at the time of purchase. We accept various payment methods and reserve the right to change our payment methods at any time.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content, designs, graphics, and other materials provided by Tekshu Innovations are protected by intellectual property laws. You agree not to reproduce, distribute, or create derivative works from any content without our prior written consent.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to suspend or terminate your access to our services at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of our services, us, or third parties, or for any other reason.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, Tekshu Innovations shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
          <ul>
            <li>Your use or inability to use our services;</li>
            <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
            <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party;</li>
            <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our services.</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Indemnification
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to indemnify and hold harmless Tekshu Innovations, its affiliates, and their respective officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our services, or your violation of these Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Tekshu Innovations is headquartered, without regard to its conflict of law principles.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify these Terms at any time. We will provide notice of such changes by posting the updated Terms on our website and updating the date at the top. Your continued use of our services after any changes to these Terms constitutes your acceptance of the new Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at:
        </Typography>
        <Typography variant="body1">
          <strong>Tekshu Innovations</strong><br />
          Email: shivmouryacse@gmail.com<br />
          Phone: 9608413215<br />
          Address: LNJPIT Chapra
        </Typography>
      </Paper>
    </Container>
  );
};

export default TermsOfService;

