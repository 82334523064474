import React, { useState } from 'react';
import {
  Container, TextField, Button, Typography, Box, Alert, IconButton, InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ReCAPTCHA from 'react-google-recaptcha';
import GradientCircularProgress from '../assets/GradientCircularProgress';

export default function LoginForm({createOne, clickLogin, loading}) {
  const [developerId, setDeveloperId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [error, setError] = useState('');


  const handleLogin = async (event) => {
    event.preventDefault();
    if (!captchaValid) {
      setError('Please complete the CAPTCHA validation.');
      return;
    }
    
    const loginData = {
      Id: developerId,
      Password: password
    };
  
    clickLogin(loginData);
    
  };
  

  const handleCaptchaChange = (value) => {
    setCaptchaValid(!!value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="xs" style={{backgroundColor:'white', borderRadius:'5px'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Developer Sign in
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%' }}>{error}</Alert>}
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="developerId"
            label="Developer ID"
            name="developerId"
            autoComplete="developer-id"
            autoFocus
            value={developerId}
            onChange={(e) => setDeveloperId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <ReCAPTCHA
              sitekey="6LdwheopAAAAAFmhBPHH7_dkrbJ-bg7e_CueGPZJ"
              onChange={handleCaptchaChange}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading?<GradientCircularProgress/>:"Login"}
          </Button>
          <Typography>Don't have a developer account<Button variant='text' onClick={createOne}>Create One</Button></Typography>
        </Box>
      </Box>
    </Container>
  );
}
