import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: "left",
}));

const Section = styled("section")(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const About = () => {
  return (
    <StyledContainer>
      <Typography variant="h3" gutterBottom>
        About Tekshu Innovations
      </Typography>
      <Typography variant="body1" paragraph>
        At Tekshu Innovations, we are dedicated to providing top-notch technical
        solutions to meet the diverse needs of our clients. Our services include
        app development, website development, API services, and various other
        technical solutions. We also offer a range of template-based app and
        website projects that can be customized to fit specific requirements.
      </Typography>
      <Typography variant="body1" paragraph>
        Our team of skilled professionals is committed to delivering innovative
        and efficient solutions to help businesses thrive in the digital age. We
        take pride in our ability to understand our clients' needs and provide
        tailored solutions that drive growth and success.
      </Typography>
      <Section>
        <Typography variant="h4" gutterBottom>
          Our Partner: M/S Kushwaha Poultry Farm
        </Typography>
        <Typography variant="body1" paragraph>
          We are proud to partner with M/S Kushwaha Poultry Farm, a leading name
          in the poultry industry. This partnership enables us to combine our
          technical expertise with their industry knowledge to create innovative
          solutions tailored to the agricultural sector. Together, we are
          working towards achieving new heights of success and innovation.
        </Typography>
      </Section>
    </StyledContainer>
  );
};

export default About;
