import React from "react";
import "../utils/style.css";
import ProfileCard from "../utils/ProfileCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Contact() {
  const data = [
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
    {
      name: "Jhon Doe",
      bio: "Front-End Developer",
      image: "/backgrounds/developerImage/Shiv.jpg",
      qoutes:
        "Technology is a relentless force, shaping our future with every innovation. It bridges gaps, solves problems, and propels us forward. Yet, it is our responsibility to harness it wisely, ensuring it enhances humanity, fosters connection, and uplifts society while preserving our values and environment.",
    },
  ];

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    
      <div className="slider-container">
        <Slider {...settings}>
          {data.map((item) => (
            <ProfileCard data={item} />
          ))}
        </Slider>
      </div>
    </>
  );
}
