import React from "react";



export default function JoinUs() {

  

  return (
    <div style={{ textAlign: "center" }}>
      
    </div>
  );
}
