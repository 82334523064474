import React from "react";
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: "left",
}));

const Section = styled("section")(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const RefundReturnPolicyPage = () => {
  return (
    <StyledContainer>
      <Typography variant="h3" gutterBottom>
        Refund and Return Policy
      </Typography>
      <Typography variant="subtitle1">Last updated: [Date]</Typography>
      <Typography variant="body1" paragraph>
        Thank you for shopping at Tekshu Innovations. If you are not entirely
        satisfied with your purchase, we're here to help.
      </Typography>
      <Section>
        <Typography variant="h4" gutterBottom>
          Refund Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We offer refunds on eligible products within 30 days of the original
          purchase date. To be eligible for a refund, your item must be unused,
          in the same condition that you received it, and in the original
          packaging. You must also provide proof of purchase.
        </Typography>
        <Typography variant="body1" paragraph>
          Once we receive your returned item, we will inspect it and notify you
          that we have received your returned item. We will immediately notify
          you on the status of your refund after inspecting the item.
        </Typography>
        <Typography variant="body1">
          If your return is approved, we will initiate a refund to your original
          method of payment. You will receive the credit within a certain amount
          of days, depending on your card issuer's policies.
        </Typography>
      </Section>
      <Section>
        <Typography variant="h4" gutterBottom>
          Return Policy
        </Typography>
        <Typography variant="body1" paragraph>
          In addition to our refund policy, we also accept returns for exchange
          or store credit within 30 days of the original purchase date. To be
          eligible for a return, your item must be unused, in the same condition
          that you received it, and in the original packaging. You must also
          provide proof of purchase.
        </Typography>
        <Typography variant="body1" paragraph>
          Once we receive your returned item, we will inspect it and notify you
          that we have received your returned item. We will immediately notify
          you on the status of your return after inspecting the item.
        </Typography>
        <Typography variant="body1" paragraph>
          If your return is approved, we will process the exchange or issue
          store credit, depending on your preference.
        </Typography>
      </Section>
      <Section>
        <Typography variant="h4" gutterBottom>
          No Refund and Return Policy for Software-related Products
        </Typography>
        <Typography variant="body1" paragraph>
          Please note that software-related products, including source code, are
          not eligible for refunds or returns. Once purchased, software-related
          products cannot be returned or exchanged due to their digital nature.
        </Typography>
      </Section>
      <Section>
        <Typography variant="h4" gutterBottom>
          Exceptions
        </Typography>
        <Typography variant="body1">
          Please note that certain items are not eligible for refunds or
          returns, including:
        </Typography>
        <ul>
          <li>Customized products or services</li>
          <li>Digital products or services</li>
          <li>
            Products or services purchased on sale or with a discount code
          </li>
          <li>
            Products or services that have been used or damaged after receipt
          </li>
        </ul>
      </Section>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1">
        If you have any questions about our Refund and Return Policy, please
        contact us at:
      </Typography>
      <Typography variant="body1">
        <strong>Tekshu Innovations</strong>
        <br />
        Email: shivmouryacse@gmail.com
        <br />
        Phone: 9608413215
        <br />
        Address: LNJPIT Chapra
      </Typography>
    </StyledContainer>
  );
};

export default RefundReturnPolicyPage;
