import React, { useState } from "react";
import PaperModel from "../utils/PaperModel";
import { Container } from "@mui/material";
import ProductCarousel from "../utils/ProductCarousel";
import PaymentDialog from "../utils/PaymentDialog";
import ImageCarouseler from "../utils/ImageCarouseler";
import useIntersectionObserver from "../assets/useIntersectionObserver";

export default function Dashboard() {
  const data1 = [
    {
      title: "Cutting Edge Solution",
      description:
        "Leveraging the latest technologies and innovative approaches, we provide solutions that are at the forefront of industry advancements, ensuring you stay ahead of the competition",
      image: "/backgrounds/tech_back.jpg",
    },
    {
      title: "High Quality",
      description:
        "Our commitment to excellence guarantees that every product and service we offer meets the highest standards of quality, delivering reliable and outstanding performance",
      image: "/backgrounds/tech_back.jpg",
    },
    {
      title: "Fast Service",
      description:
        "We prioritize efficiency and speed without compromising on quality, ensuring that your needs are met promptly and effectively, keeping your operations running smoothly",
      image: "/backgrounds/tech_back.jpg",
    },
  ];

  const products = [
    {
      name: "E-Learning App",
      description: "This is a short description of product 1.",
      price: 59999.0,
      rating: 4.5,
      image: "/backgrounds/app.jpg",
      otherInfo: "Additional information about product 1.",
    },
    {
      name: "Chatting App",
      description: "This is a short description of product 2.",
      price: 40000.0,
      rating: 4.0,
      image: "/backgrounds/app.jpg",
      otherInfo: "Additional information about product 2.",
    },
    {
      name: "Email Auth API",
      description: "This is a short description of product 1.",
      price: 99.0,
      rating: 2.0,
      image: "/backgrounds/app.jpg",
      otherInfo: "Additional information about product 1.",
    },
    {
      name: "Realtime Database API",
      description: "This is a short description of product 2.",
      price: 1.0,
      rating: 1.5,
      image: "/backgrounds/app.jpg",
      otherInfo: "Additional information about product 2.",
    },
  ];

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState("");
  const { elementRef, isVisible } = useIntersectionObserver();
  const { elementRef2, isVisible2 } = useIntersectionObserver();
  const imageData = [
    {
      title: "Hello",
      img: "/backgrounds/app.jpg",
      details: "jashbfcjh fyc ysgy cejygjysg gcjeygrjyg",
    },
    {
      title: "Hello 2",
      img: "/backgrounds/app.jpg",
      details: "jashbfcjh fyc ysgy cejygjysg gcjeygrjyg",
    },
    {
      title: "Hello 3",
      img: "/backgrounds/app.jpg",
      details: "jashbfcjh fyc ysgy cejygjysg gcjeygrjyg",
    },
    {
      title: "Hello 4",
      img: "/backgrounds/app.jpg",
      details: "jashbfcjh fyc ysgy cejygjysg gcjeygrjyg",
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleProductBuy = (value) => {
    console.log(value);
    setProduct(products[value]);
  };
  return (
    <div className="dashboard-container">
      <div className="dashboard-video-view">
        <ImageCarouseler data={imageData}/>
      </div>

      <div
        className={`fade-anim ${isVisible ? "fade-in" : ""}`}
        ref={elementRef}
      >
        <PaperModel data={data1} />
      </div>

      <Container
        className={`fade-anim ${isVisible2 ? "fade-in" : ""}`}
        ref={elementRef2}
        style={{
          paddingBottom: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ProductCarousel
          products={products}
          productBuy={handleProductBuy}
          open={handleOpen}
        />
      </Container>
      <PaymentDialog open={open} handleClose={handleClose} product={product} />
    </div>
  );
}
