import React from 'react';
import { Container, Typography, Paper, Divider } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2em' }}>
      <Paper elevation={3} style={{ padding: '2em' }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated: 6 June 2024
        </Typography>

        <Divider style={{ margin: '1em 0' }} />

        <Typography variant="body1" paragraph>
          Welcome to Tekshu Innovations. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This privacy policy outlines how we collect, use, and protect your information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>a. Personal Information</strong><br />
          We may collect the following personal information from you:
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Billing and shipping address</li>
            <li>Payment information</li>
          </ul>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>b. Non-Personal Information</strong><br />
          We may collect non-personal information such as:
          <ul>
            <li>Browser type</li>
            <li>Operating system</li>
            <li>IP address</li>
            <li>Usage data (e.g., pages visited, time spent on the site)</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information to:
          <ul>
            <li>Provide and manage our services</li>
            <li>Process your transactions</li>
            <li>Communicate with you about your orders and our services</li>
            <li>Improve our website and services</li>
            <li>Send promotional materials and updates (with your consent)</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:
          <ul>
            <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website and conducting our business.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights.</li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files stored on your device that help us understand your preferences and provide personalized services. You can disable cookies through your browser settings, but this may affect your ability to use some features of our site.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement a variety of security measures to protect your personal information. These measures include encryption, secure access protocols, and regular security assessments. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
          <ul>
            <li>Access the personal information we hold about you</li>
            <li>Request corrections to any inaccuracies</li>
            <li>Request the deletion of your personal information</li>
            <li>Object to the processing of your personal information</li>
            <li>Withdraw your consent at any time</li>
          </ul>
          To exercise these rights, please contact us at [contact email].
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Third-Party Links
        </Typography>
        <Typography variant="body1" paragraph>
          Our website may contain links to third-party sites. These sites have their own privacy policies, and we are not responsible for their content or practices. We encourage you to review the privacy policies of any third-party sites you visit.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete such information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on our website and updating the date at the top.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this privacy policy, please contact us at:
        </Typography>
        <Typography variant="body1">
          <strong>Tekshu Innovations</strong><br />
          Email: shivmouryacse@gmail.com<br />
          Phone: 9608413215<br />
          Address: LNJPIT Chapra
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;

