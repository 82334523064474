import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    // Store the current ref value in a local variable
    let currentElement = elementRef.current;
    
    // Create a new observer instance
    observerRef.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    });

    // Start observing the current element
    if (currentElement) {
      observerRef.current.observe(currentElement);
    }

    // Cleanup function
    return () => {
      // Ensure to unobserve the originally observed element
      if (currentElement && observerRef.current) {
        observerRef.current.unobserve(currentElement);
      }
    };
  }, []); // Dependency array ensures effect runs only on mount and unmount

  // Re-observe when elementRef or isVisible changes
  useEffect(() => {
    if (isVisible && observerRef.current) {
      observerRef.current.observe(elementRef.current);
    }
  }, [isVisible]);

  return { elementRef, isVisible };
};

export default useIntersectionObserver;
